
import React,{ useState, useEffect } from 'react';
import { Box } from '@material-ui/core';

import Diagram from './Diagram';
import SmallDiagram from './SmallDiagram';
import './style.scss';

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}
const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export default function Tokenomics({ linkRef1 }) {
    const screenWidth = useWindowDimensions().width;
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        screenWidth > 700    ? setIsMobile(false) : setIsMobile(true);
    }, [screenWidth]);

    return (
        <Box className='token-container' ref={linkRef1}>
            <Box className='token-wrapper1'>
                <Box>
                    { isMobile ?
                        <SmallDiagram />
                    :
                        <Diagram />
                    }
                </Box>
                <Box>
                    <Box component='span' className='h2'>TOKENOMICS</Box>
                    <Box component='span' className='h5'>
                        <Box component='span'>
                            At each transaction 5% will be transformed into BNB for platform development, marketing and developers, 7.5% will be burned to bring a constant price increase of 2.5% for each transaction and 2.5% will go back to the goalkeepers to recover any loss.
                        </Box>
                        <Box component='span'>
                            If we had gone as usual 5-5-5 nothing would have happened, so we will bring a steady increase in the token by 2.5% on each transaction.
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
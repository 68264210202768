
import React from 'react';
import { Box } from '@material-ui/core';

// import { baseUrl } from '../../constants';
import Link1 from '../../assets/image/footer/link1.svg';
import Link2 from '../../assets/image/footer/link2.svg';
import Link3 from '../../assets/image/footer/link3.svg';
import Logo from '../../assets/image/header/logo.png';
import './style.scss';

export default function Footer() {
    return (
        <Box className='footer-container'>
            {/* <Box className='footer-badge'>
                <Box className='footer-badge-img'>
                    <Box component='img' src={baseUrl + 'image/footer/small-preview.png'} />
                </Box>
                <Box className='footer-ready-lorem'>
                    <Box className='h3' component='span'>Ready to start?</Box>
                    <Box className='h4' component='span'>Open the DApp now to get going.</Box>
                </Box>
                <button className='footer-start-btn'>
                    <Box className='h6' component='span'>Start DApp</Box>
                    <Box component='img' src={baseUrl + 'image/footer/arrow.svg'} />
                </button>
            </Box> */}
            <Box className='footer-last'>
                <Box component='img' src={Logo} />
                <Box component='span'>© 2021 SIVSWAP. All rights reserved.</Box>
                <Box>
                    <Box component='img' src={Link1} />
                    <Box style={{cursor: 'pointer'}} component='img' src={Link2} onClick={() => {window.open("https://github.com/sivtoken", "_blank")}} />
                    <Box style={{cursor: 'pointer'}} component='img' src={Link3} onClick={() => {window.open("https://t.me/sivtokens", "_blank")}}/>
                </Box>
                <Box component='span'>Contact: <a href="mailto:support@sivtoken.org">support@sivtoken.org</a></Box>
            </Box>
        </Box>
    );
}
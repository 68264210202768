import React from 'react';
import { Box } from '@material-ui/core';

// import { baseUrl } from '../../constants';
import Plus from '../../assets/image/faqs/plus.svg';

import './style.scss';

export default function FAQCard({ title, content }) {
    const [open, setOpen] = React.useState(false);

    const expandRef = React.useRef(null);
    const accordionRef = React.useRef(null);

    const handleToggle = () => {
        if (open) {
            accordionRef.current.classList.remove('active');
            expandRef.current.classList.remove('active');
        }
        else {
            accordionRef.current.classList.add('active');
            expandRef.current.classList.add('active');
        }
        setOpen(!open);
    }
    return (
        <Box className='faq-card' ref={accordionRef} onClick={() => handleToggle()}>
            <Box>
                <Box>
                    <Box className='h5' component='span'>{title}</Box>
                    <Box ref={expandRef} component='img' src={Plus} />
                </Box>
                { open &&
                    <>
                        <Box></Box>
                        <Box component='span'>
                            {content}
                        </Box>
                    </>
                }
            </Box>
        </Box>

    );
};

import { Box } from '@material-ui/core';
import React, { useRef } from 'react';

import Header from '../../components/Header'
import Welcome from '../../components/Welcome';
// import Benefits from '../../components/Benefits';
import Providers from '../../components/Providers';
import Tokenomics from '../../components/Tokenomics';
import Roadmap from '../../components/Roadmap';
import FAQs from '../../components/FAQs';
import Footer from '../../components/Footer';

// import { baseUrl } from '../../constants';
import RocketBG from '../../assets/image/tokenomics/rocket.png';

import './style.scss';

export default function Firstpage() {
    const linkRef1 = useRef(null);
    const linkRef2 = useRef(null);
    const linkRef3 = useRef(null);
    const linkRef4 = useRef(null);

    return (
        <Box className='root'>
            <Box className='godfather-wrapper'>
                <Header linkRef1={linkRef1} linkRef2={linkRef2} linkRef3={linkRef3} linkRef4={linkRef4} />
                <Welcome />
            </Box>
            {/* <Benefits /> */}
            <Providers />
            <Box className='token-road-wrapper'>
                <Tokenomics linkRef1={linkRef1}/>
                <Roadmap linkRef2={linkRef2}/>
                <Box component='img' className='rocket-img' src={RocketBG} />
            </Box>
            <FAQs linkRef3={linkRef3}/>
            <Footer linkRef4={linkRef4}/>
        </Box>
    );
}
import React from 'react';
import { Box } from '@material-ui/core';

// import { baseUrl } from '../../constants';
import Preperation from '../../assets/image/roadmap/preperation.svg';
import Presale from '../../assets/image/roadmap/presale.svg';
import Marketing from '../../assets/image/roadmap/marketing.svg';
import Upcoming from '../../assets/image/roadmap/upcoming.svg';

import './style.scss';

export default function Roadmap({ linkRef2 }) {
    return (
        <Box className='roadmap-container' ref={linkRef2}>
            <Box className='h2' component='span'>SIVMAP</Box>
            <Box className='h5' component='span'>Join SIVSWAP on our journey to the moon!</Box>
            <Box className='roadmap-grid'>
                <Box className='roadmap-item'>
                    <Box>
                        <Box className='roadmap-icon'>
                            <Box component='img' src={Preperation} />
                        </Box>
                        <Box className='roadmap-detail'>
                            <Box className='h5' component='span'>Q4</Box>
                            <Box component='span'>2021</Box>
                        </Box>
                    </Box>
                    <Box component='span' className='h7'>
                        Marketing push to spread awareness, Website launch, 5000 telegram members, 5000 twitter members, 1st  SIVToken song, Multiswap, Third party audit, Intro video, Pre-Sale
                    </Box>
                </Box>
                <Box className='roadmap-item'>
                    <Box>
                        <Box className='roadmap-icon'>
                            <Box component='img' src={Presale} />
                        </Box>
                        <Box className='roadmap-detail'>
                            <Box className='h5' component='span'>Q1</Box>
                            <Box component='span'>2022</Box>
                        </Box>
                    </Box>
                    <Box component='span' className='h7'>
                        Influencer marketing push, Listing on coin gecko, Listing on coin market cap, 15,000 telegram members, 15,000 holders, NFT’s creation, 2nd SIVToken song, Listing on CEX
                    </Box>
                </Box>
                <Box className='roadmap-item'>
                    <Box>
                        <Box className='roadmap-icon'>
                            <Box component='img' src={Marketing} />
                        </Box>
                        <Box className='roadmap-detail'>
                            <Box className='h5' component='span'>Q2</Box>
                            <Box component='span'>2022</Box>
                        </Box>
                    </Box>
                    <Box component='span' className='h7'>
                        80,000 holders, 80,000 telegram members, Large influencer marketing push, Billboards in Times Square, More NFT’s, Tier 1 CEX, 3rd SIVToken song
                    </Box>
                </Box>
                <Box className='roadmap-item'>
                    <Box>
                        <Box className='roadmap-icon'>
                            <Box component='img' src={Upcoming} />
                        </Box>
                        <Box className='roadmap-detail'>
                            <Box className='h5' component='span'>Q3</Box>
                            <Box component='span'>2022</Box>
                        </Box>
                    </Box>
                    <Box component='span' className='h7'>
                        150,000 holders, 150,000 telegram members, SIVCITY NFT marketplace, Large influencer marketing push, Tier 1 CEX, 4th SIVToken song
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@material-ui/core';

// import { baseUrl } from '../../constants';
import Logo from '../../assets/image/header/logo.png';
import Menu from '../../assets/image/header/menu.svg';
import Connect from '../../assets/image/header/connect.svg';

import './style.scss';

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}
const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export default function Header({ linkRef1, linkRef2, linkRef3, linkRef4}) {
    const screenWidth = useWindowDimensions().width;
    const [isMobile, setIsMobile] = useState(false);
    const [open, setOpen] = useState(false);
    const mobileMenuRef = useRef(null);

    useEffect(() => {
        screenWidth > 1220 ? setIsMobile(false) : setIsMobile(true);
    }, [screenWidth]);

    const handleToggleMenu = () => {
        open ? mobileMenuRef.current.classList.remove('active') : mobileMenuRef.current.classList.add('active')
        setOpen(!open);
    }

    const handleNavigate = ( handler ) => {
        if(mobileMenuRef.current) mobileMenuRef.current.classList.remove('active');
        setOpen(false);
        handler.current.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <>
            { isMobile ?
                <>
                    <Box className='header-mobile-container'>
                        <Box onClick={() => handleToggleMenu()}>
                            <Box component='img' src={Menu} />
                        </Box>
                        <Box component='img' src={Logo} />
                        <Link to='/dApp'>
                            <Box>
                                <Box component='img' src={Connect} />
                            </Box>
                        </Link>
                    </Box>
                    <Box className='header-mobile-menu' ref={mobileMenuRef}>
                        {/* <Link to='/'>Audit</Link> */}
                        <Link onClick={() => handleNavigate(linkRef1)} to='/'>Tokenomics</Link>
                        <Link onClick={() => handleNavigate(linkRef2)} to='/'>Roadmap</Link>
                        <Link onClick={() => handleNavigate(linkRef3)} to='/'>Q&A</Link>
                        <Link to={{ pathname: "https://bscscan.com/token/0xA53BB0d66C508Df2c1874950C307147aB6281478" }} target="_blank">Contract</Link>
                    </Box>
                </>
                :
                <Box className='header-container'>
                    <Box className='header-logo'>
                        <Box component='img' src={Logo} />
                    </Box>
                    <Box className='header-links h7'>
                        {/* <Link to='/'>{'Audit'}</Link> */}
                        <Link onClick={() => handleNavigate(linkRef1)} to='/'>{'Tokenomics'}</Link>
                        <Link onClick={() => handleNavigate(linkRef2)} to='/'>{'Roadmap'}</Link>
                        <Link onClick={() => handleNavigate(linkRef3)} to='/'>{'Q&A'}</Link>
                        <Link to={{ pathname: "https://bscscan.com/token/0xA53BB0d66C508Df2c1874950C307147aB6281478" }} target="_blank">{'Contract'}</Link>
                    </Box>
                    {/* <Box className='header-openapp'>
                        <Box component='button' className='header-pink-btn h6'>
                            Open DApp
                        </Box>
                    </Box> */}
                </Box>
            }
        </>
    );
}

import React from 'react';
import { Box } from '@material-ui/core';

import FAQCard from './FAQCard';

import './style.scss';

export default function FAQs({ linkRef3 }) {
    return (
        <Box className='faq-container' ref={linkRef3}>
            <Box className='h2' component='span'>Frequently Asked Questions</Box>
            <Box className='h5' component='span'>Prepared below are some frequently asked questions about our project. Feel free to drop a message in our Telegram group if you still have doubts</Box>
            <Box>
                <FAQCard title={'Is it safe to invest in SIVToken?'} content={`SIVSWAP's presale was held on Oxbull Launchpad. All the liquidity has been locked for 1 year. Our token is also tech-safe and audited by Solid Group.`} />
                <FAQCard title={`What's SIVToken's staking reward?`} content={'Unlike other staking platforms, with SIVSWAP, staking is completely automatic. Your SIVToken will never need to leave your wallet to be staked. There is an 10% tax on all transactions collected as reward for stakers.'} />
                <FAQCard title={`What's SIVToken?`} content={`SIV means Special Immigration Visa.`} />
            </Box>
        </Box>
    );
}
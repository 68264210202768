
import React from 'react';
import { Box } from '@material-ui/core';

// import { baseUrl } from '../../constants';
import Blogo from '../../assets/image/welcome/blogo.png';

import './style.scss';

export default function Welcome() {
    return (
        <Box className='welcome-container'>
            <Box className='welcome-second'>
                <Box component='img' src={Blogo} />
            </Box>
            <Box className='welcome-first'>
                <Box component='span' className='h1'>
                    {'Welcome to '}
                    <Box component='span'>SIVToken</Box>
                </Box>

                <Box component='span' className='h5'>
                The true leader of all the sivtokens Multiswap, BOT, Decentralized, NFT’s, Gaming, Safe Crypto Marketing Platform With a smart way to constantly increase the price of the token.
                </Box>
                <Box className='welcome-actions'>
                    <Box className='h6' component='button' onClick={() => {window.open("https://pancakeswap.finance/swap", "_blank")}}>Buy on Pancakeswap</Box>
                </Box>
            </Box>
        </Box>
    );
}
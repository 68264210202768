
import React from 'react';
import { Box } from '@material-ui/core';

import './style.scss';

export default function Diagram() {
    return (
        <Box className='diagram-container' mt={4}>
            <Box>
                <Box>
                    <Box>5% to BNB</Box>
                    <Box className='diagram-content'>Platform development, marketing and developers</Box>
                </Box>
                <Box>
                    <Box>7.5%</Box>
                    <Box className='diagram-content'>Burn</Box>
                </Box>
                <Box>
                    <Box>2.5%</Box>
                    <Box className='diagram-content'>Price increase</Box>
                </Box>
                <Box>
                    <Box>2.5%</Box>
                    <Box className='diagram-content'>Goalkeepers</Box>
                </Box>
            </Box>
        </Box>
    );
}
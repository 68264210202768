
import React from 'react';
import { Box } from '@material-ui/core';

// import { baseUrl } from '../../constants';
import Sniper from '../../assets/image/providers/sniper.svg';
import Orders from '../../assets/image/providers/orders.svg';
import Rewards from '../../assets/image/providers/rewards.svg';

import './style.scss';

export default function Providers() {
    return (
        <Box className='providers-container'>
            <Box className='providers-wrapper'>
                <Box className='providers-innovative'>
                    <Box component='span'>Why should you invest in SIVToken?</Box>
                    <Box component='span'>
                        {/* {`With SIVSWAP's innovative code, all of your SIVSWAP tokens will be automatically staked for you to be rewarded in BNB and SIVSWAP tokens!`} */}
                        <span className='providers-list'>- Helping with global warming by reducing carbon monoxide.</span>
                        <span className='providers-list'>- Helping the world to fight with Hunger</span>
                        <span className='providers-list'>- Helping those who helped The U.S Missions overseas such as Interpreters, Translators and their families</span>
                        <span className='providers-list'>- Multiswap platform, making everyone able to buy new tokens in 2 clicksg sniper</span>
                        <span className='providers-list'>- Unique sivbot</span>
                        <span className='providers-list'>- Find new and amazing projects to invest</span>
                        <span className='providers-list'>- Having our own crypto space</span>
                        <span className='providers-list'>- Constant token price increase</span>
                        <span className='providers-list'>- Play an incredible game and earn high rewards</span>
                    </Box>
                </Box>
                <Box className='providers-separator'></Box>
                <Box className='providers-content'>
                    <Box>
                        <Box className='providers-icon'>
                            <Box component='img' src={Sniper} />
                        </Box>
                        <Box>
                            <Box className='providers-title h5'>
                                NFT and GAMING
                            </Box>
                            <Box className='providers-description h8'>
                                SIVCITY is going to be our upcoming game connected to a NFT Marketplace where people can buy or sell their Doge, weapons and even their own female doge to breed the strongest doge, having an interesting method of increasing the value of each NFT.The only way to buy the NFT’s is using the GRTDOGE token. We’ll also have a ranking system where the top 100 Dogemasters will be rewarded. Here you can constantly earn some power points, make your doge stronger and win also some tokens on every duel.
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Box className='providers-icon'>
                            <Box component='img' src={Orders} />
                        </Box>
                        <Box>
                            <Box className='providers-title h5'>
                                SIVBOOK
                            </Box>
                            <Box className='providers-description h8'>
                                SIVBOOK is our next platform specially created for the crypto world where investors will be able to talk to each other, place various announcements, form groups and where only projects with a very good legal basis will be promoted. New projects are not allowed to promote crypto on any existing platform and we need to make a change, many good projects are lost before they are discovered and the chance to invest in them is lost.
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Box className='providers-icon'>
                            <Box component='img' src={Rewards} />
                        </Box>
                        <Box>
                            <Box className='providers-title h5'>
                                SIVBOT
                            </Box>
                            <Box className='providers-description h8'>
                                SIVBOT will be a bot made especially for our telegram users or the future crypto marketing platform, which will announce every new listing on existing swaps in our multiswap and will help investors not to miss any important opportunity. to successfully invest in a token.
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}